'use strict';

angular.module('enervexSalesappApp').controller('PropertyCtrl', function($scope, Property, $stateParams, Flash, _, $state) {
	$scope.errors = {};
	if ($stateParams.id == "new") {
		$scope.property = {}
	} else {
		Property.get({
			id: $stateParams.id
		}).$promise.then(function(property) {
			$scope.property = property;
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			var payload = _.clone($scope.property);
			payload = _.omit(payload, "__v");
			if (!payload._id) {
				Property.save(payload).$promise.then(function(result){
					$scope.success = true;
					// $scope.property = result;
					Flash.create('success', '<strong>Success!</strong> Successfully saved proeprty.', 0, {}, true);
					$state.go("property", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err){
					$scope.errors.other = 'Problem saving';
					$scope.message = '';
				})
			} else {
				Property.update({id:payload._id}, payload).$promise.then(function(){
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully saved proeprty.', 0, {}, true);
				}).catch(function(err){
					$scope.errors.other = 'Problem saving';
					$scope.message = '';
				})
			}
		}
	};
});
